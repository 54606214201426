import { styled } from '@mui/system'
import MenuDropDown from '../menu-dropdown'

const shouldForwardProp = (prop) => !['top', 'hasOverlay'].includes(prop)

const Overlay = styled('div', { shouldForwardProp })`
	background: rgba(0, 0, 0, 0.3);
	position: absolute;
	width: 100%;
	height: 100vh;
	left: 0;
	top: ${({ hasOverlay }) => `calc(var(--header-height) - ${hasOverlay ? '30px' : '0px'})`};
`

const MenuDropDownStyled = styled(MenuDropDown)`
	z-index: 1;
	> .MuiPaper-root {
		background-color: transparent;
		max-width: ${({ theme }) => `calc(100% - ${theme.spacing(8)})`};
		margin: 0 auto;
	}
`

export { Overlay, MenuDropDownStyled }
