import MuiSwipeableDrawer from '@mui/material/SwipeableDrawer'
import { styled } from '@mui/system'
import BHButton from '../../molecules/button'

const sidebarWidth = `calc(100vw - 45px)`

const HamburgerButton = styled(BHButton)`
	padding: 5px;
`

const SwipeableDrawer = styled(MuiSwipeableDrawer)`
	> .MuiPaper-root {
		width: 100%;
		overflow-x: hidden;
		padding: 54px 18px 26px;
		will-change: auto;
		.NavMenu-root {
			min-height: max-content; /* HYDREPLAT-139 */
			width: 100%;
		}
		${({ theme }) => theme.breakpoints.up('sm')} {
			width: ${sidebarWidth};
			.NavMenu-root {
				.NavSubMenu-root {
					width: calc(100% - 45px);
				}
			}
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			max-width: 700px;
		}
	}
`

const ButtonClose = styled(BHButton)`
	right: 9px;
	top: 9px;
`

export { ButtonClose, HamburgerButton, SwipeableDrawer }
