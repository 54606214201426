import isPropValid from '@emotion/is-prop-valid'
import Link from '../link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/system'

const shouldForwardProp = (prop) =>
	isPropValid(prop) && !['type', 'component', 'items', 'maxItemsForColumn', 'parentMaxItemsForColumn'].includes(prop)

const DropDownWrapper = styled('div')`
	padding: ${({ theme }) => theme.spacing('45px', 3, 3, '10px')};
	display: block;
	border-radius: calc(var(--card-border-radius) * 3);
	margin-top: 18px;
	background-color: ${({ theme }) => theme.palette.common.white};
`
const DropDrownList = styled(List, { shouldForwardProp })`
	display: grid;
	grid-template-columns: ${({ countItems }) => `repeat(${countItems}, minmax(max-content, 1fr))`};
	grid-template-rows: 1fr;
	grid-gap: 20px;
	align-items: flex-start;
	padding: 0;
	width: 100%;
	.DropDrownList-item {
		[class*='banner-hover-trigger'] {
			position: absolute;
			visibility: hidden;
			opacity: 0;
			transition: all 0.2s ease-in-out;
			&._show {
				visibility: visible;
				opacity: 1;
			}
		}
		[class*='default-hover-trigger'] {
			opacity: 1;
			transition: all 0.2s ease-in-out;
			&._hide {
				visibility: hidden;
				opacity: 0;
			}
		}
	}
`

const DropDrownListItem = styled(ListItem, {
	shouldForwardProp,
})`
	display: block;
	padding: 0;
	padding-left: ${({ type }) => (type === 'cms_block' ? '0px' : '30px')};
	& .MuiList-root {
		display: block;
		padding: 0;

		& .MuiListItem-root {
			padding-left: 0;
		}
	}
	&.menu-item-current {
		> a:after {
			width: 100%;
		}
		&:hover {
			> a:after {
				background-color: ${({ theme }) => theme.palette.grey.main};
			}
		}
	}
`

const NavLinkStyled = styled(Link)`
	color: inherit;
	cursor: pointer;
	display: inline-block;
	line-height: 18px;
	margin-bottom: ${({ ...props }) => (props.variant === 'h6' ? '24px' : '10px')};
	transition: color 0.2s;
	transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	${({ ...props }) =>
		props.variant === 'h6'
			? `
	font-size: ${({ theme }) => theme.typography.pxToRem(20)} !important;
	line-height: ${({ theme }) => theme.typography.pxToRem(23)};
	font-family: ${({ theme }) => theme.typography.fontFamilyAlternative};
	`
			: ''}
	&:not(.no-link):hover {
		color: ${({ theme }) => theme.palette.grey.main};
	}
	&:after {
		content: '';
		display: block;
		width: 0px;
		height: 1px;
		background-color: ${({ theme }) => theme.palette.primary.main};
		margin-top: -1px;
		transition: width 0.3s;
		transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	}
	&.last {
		margin-bottom: 18px;
	}
	&.no-link {
		cursor: default;
	}
`

const AdditionalBlock = styled('div')`
	width: 100%;
	padding: 12px 68px;
	text-align: center;
	border-top: 1px solid ${({ theme }) => theme.palette.grey.light};
	& p {
		margin: 0;
	}
`
const ChildrenWrapper = styled('div')`
	display: flex;
	gap: 15px;
`

export { DropDownWrapper, DropDrownList, DropDrownListItem, NavLinkStyled, AdditionalBlock, ChildrenWrapper }
