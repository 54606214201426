import { styled } from '@mui/system'
import IconHeadArrowDown from '../../atoms/icons/arrowhead-down-icon'
import Typography from '../../atoms/typography'
import BHLink from '../link'

const shouldForwardProp = (prop) => !['columns', 'toggleDropdown', 'routeChanging', 'iconDropdownProps'].includes(prop)

const NavMenu = styled('nav', { shouldForwardProp })`
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: repeat(${({ columns }) => columns}, max-content);
	grid-gap: 7.5%;
	pointer-events: ${({ routeChanging }) => (routeChanging ? 'none' : 'auto')};
`

const NavItem = styled(Typography)`
	display: flex;
	flex: 1 0 auto;
	align-items: center;
	color: ${({ theme }) => theme.palette.primary.main};
	text-transform: uppercase;
	position: relative;

	> .MuiSvgIcon-root {
		transition: transform 0.3s;
		transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
		color: ${({ theme }) => theme.palette.grey.main};
	}

	&:after {
		content: '';
		position: absolute;
		bottom: -24px;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 0;
		height: 0;
		transition: border-bottom 0.3s;
		transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	}

	&:hover {
		a:after {
			width: 100%;
		}
	}
	&.nav-dropdown-opened {
		.MuiSvgIcon-root {
			transform: rotate3d(0, 0, 1, -180deg);
		}
		a:after {
			width: 100%;
		}
		&:after {
			border-left: 14px solid transparent;
			border-right: 14px solid transparent;
			border-bottom: ${({ theme }) => `16px solid ${theme.palette.common.white}`};
		}
	}
	&.nav-active {
		a:after {
			width: 100%;
		}
	}
`

const LinkRoot = styled('span')`
	display: flex;
	align-items: center;
	.MuiSvgIcon-root {
		display: none;
	}
`

const Link = styled(BHLink, { shouldForwardProp })`
	color: inherit;
	cursor: pointer;
	padding: 0 5px;
	display: inline-block;
	line-height: 18px;
	transition: color 0.2s;
	transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};

	&:after {
		content: '';
		display: block;
		width: 0px;
		height: 2px;
		background-color: currentColor;
		margin-top: 6px;
		transition: width 0.3s;
		transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	}
`

const IconDropdown = styled(IconHeadArrowDown)`
	cursor: pointer;
`

export { IconDropdown, Link, LinkRoot, NavItem, NavMenu }
