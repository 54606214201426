import { Grid as MuiGrid, Stack as MuiStack } from '@mui/material'
import { styled } from '@mui/system'
import Menu from '../../molecules/menu/menu'
import { Link } from '../../molecules/menu/menu-first-level.styled'

const shouldForwardProp = (prop) => !['message', 'position'].includes(prop)

const Header = styled('header', { shouldForwardProp })``

const GridContainer = styled(MuiGrid)`
	padding: ${({ theme }) => theme.spacing(0.5, 5.5)};
	position: relative;
	z-index: 1;
	.mini-trigger {
		padding: 0;
	}
`

const LogoWrapper = styled('div')`
	max-width: 150px;
	width: 100%;
`

const MenuStyled = styled(Menu)`
	display: block;
	${Link} {
		padding: ${({ theme }) => theme.spacing(2, '5px', 1)};
		font-family: ${({ theme }) => theme.typography.fontFamilyAlternative};
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
		line-height: ${({ theme }) => theme.typography.pxToRem(20)};
	}
`

const MuiStackStyled = styled(MuiStack)`
	> span {
		background-color: ${({ theme }) => theme.palette.primary.main};
		width: 2px;
	}
	> :not(style) + :not(style) {
		margin-left: 18px;
	}
	.MuiSvgIcon-root {
		font-size: ${({ theme }) => theme.typography.pxToRem(22)} !important;
	}
`

export { GridContainer, Header, LogoWrapper, MenuStyled, MuiStackStyled }
